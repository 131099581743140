<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">

      <div class="container">
        <div class="box" style="align-content: flex-start; flex-direction: row; justify-content: space-between; display: flex;">

          <h4 class="title2">My Forms</h4>

          <div class="field has-addons is-pulled-right">



          </div>


        </div>
      </div>


    </section>
    <section  v-if="loaded" class="section">
      <div >
        <div v-for="(fg, i) in forms.form.formFieldGroups"  :key="i" class="container">

          <div v-for="field in fg.fields" :key="field.name">

            <textFieldEditor v-if="field.fieldType == 'text'" :fieldData="field" />
            <pre v-else >{{ field }}</pre>
            <hr style="color:black; border: solid; max-width: 50%; margin:auto;" />

          </div>


          <hr style="color:black; border: solid;" />





        </div>

      </div>

    </section>
  </div>






</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import { Icon } from "@iconify/vue2";
//import lunch from "helpers/lunch";
//import { format, sub as datesub } from 'date-fns' ;
//import { sum } from 'pdf-lib';
//import { toast } from 'bulma-toast'

import textFieldEditor from "Components/form/edit/text.vue"

//import LunchOption from "./LunchOption.vue";


export default {
  name: "home",

  components: {
    textFieldEditor,
  },

  data: function () {
    return {
      forms: null,
      loaded: false,
    };
  },

















  async mounted() {
    this.loaded = false;
    this.$api
      .get("v2/staff/forms/"+this.$route.params.formID, true, true)
      .then((response) => {
        this.forms = response
      })
      .finally(() => {
        this.loaded = true;
      });

    //this.loadRegister();
  },
};
</script>

