<template>

<div>
    <div class="field">
        <label class="label">Label for Text Input</label>
        <input class="input"  type="text"  v-model.trim="myLabel" placeholder="Example text">
    </div>

    <div class="field">
        <label class="label">Place holder for Text Input</label>
        <input class="input"  type="text"  v-model.trim="myPlaceHolder" placeholder="Example text">
    </div>

    <div class="field">
        <label class="label">Description</label>
        <input class="input"  type="text"  v-model.trim="myDescription" placeholder="Example text">
    </div>

    <div class="field">
    <label class="checkbox">
        <input type="checkbox" :value="true" v-model="myRequired" />
        Required
    </label>
    </div>

    Preview

    <div class="field">
        <label class="label">{{ myLabel }}</label>
        <div class="control">
            <input  class="input" :class="isRequiredFieldClass()" type="text" :required="myRequired"
                 :placeholder="myPlaceHolder">
        </div>
    </div>
    <p class="help" v-if="myDescription">{{ myDescription }}</p>

        <div v-if="myFieldData.links?.length" style="padding-top: 4px;">
            <p class="is-1" style="font-weight: bold; font-size:medium;">External Links</p>
            <ul>
                <li v-for="l in myFieldData.links" :key="l.link" style="margin-left: 2em;">
                    <a :href="l.link" target="_blank">{{ l.name || "External Link" }}</a>
                </li>
            </ul>
        </div>
        <p class="help is-danger" style="(!errorMsg) ? 'visibility: hidden;' : ''">{{ errorMsg }}&nbsp;</p>

        </div>




</template>
<script>


/*
isEmail,
  isURL,
  isMACAddress,
  isIP,
  isIPRange,
  isFQDN,
  isBoolean,
  isIBAN,
  isBIC,
  isAlpha,
  isAlphaLocales,
  isAlphanumeric,
  isAlphanumericLocales,
  isNumeric,
  isPassportNumber,
  isPort,
  isLowercase,
  isUppercase,
  isAscii,
  isFullWidth,
  isHalfWidth,
  isVariableWidth,
  isMultibyte,
  isSemVer,
  isSurrogatePair,
  isInt,
  isIMEI,
  isFloat,
  isFloatLocales,
  isDecimal,
  isHexadecimal,
  isOctal,
  isDivisibleBy,
  isHexColor,
  isRgbColor,
  isHSL,
  isISRC,
  isMD5,
  isHash,
  isJWT,
  isJSON,
  isEmpty,
  isLength,
  isLocale,
  isByteLength,
  isUUID,
  isMongoId,
  isAfter,
  isBefore,
  isIn,
  isLuhnValid,
  isCreditCard,
  isIdentityCard,
  isEAN,
  isISIN,
  isISBN,
  isISSN,
  isMobilePhone,
  isMobilePhoneLocales,
  isPostalCode,
  isPostalCodeLocales,
  isEthereumAddress,
  isCurrency,
  isBtcAddress,
  isISO6391,
  isISO8601,
  isRFC3339,
  isISO31661Alpha2,
  isISO31661Alpha3,
  isISO4217,
  isBase32,
  isBase58,
  isBase64,
  isDataURI,
  isMagnetURI,
  isMimeType,
  isLatLong,
  isSlug,
  isStrongPassword,
  isTaxID,
  isDate,
  isLicensePlate,
  isVAT,
  ibanLocales,
*/

const permittedFields = [ 'dependentFieldFilters', 'label', 'placeholder', 'required', 'name', 'fieldType', 'validations']

export default {
    name: 'textFieldEditor',
    props: ["fieldData", "index", "value"],
    data: function () {
        return {
            myValue: this.value,
            myLabel: this.fieldData?.label,
            myPlaceHolder: this.fieldData?.placeholder,
            myRequired: this.fieldData?.required,
            myDescription: this.fieldData?.description,
            errorMsg: false,
            avoid_changes: 0,
            myFieldData: null,
        }
    },

    mounted() {
        this.myFieldData = {}

        console.log(permittedFields)

        permittedFields.forEach(e => {
            console.log(e)
            this.myFieldData[e] = this.fieldData[e]
        })


    },

    methods: {
        isRequiredFieldClass: () => {
            return false
        }
    }



}
</script>

<style>
div.ul-box {
    float: none;
    border: thin;
    border-style: solid;
    padding: 5px;
}


div.ul-box.is-danger {
    border-color: red;
}



div.ul-box.is-success {
    border-color: green;
}
</style>